import React from "react";
import { Stack, Typography, CircularProgress } from "@mui/material";
import { ReactComponent as Logo } from "assets/logo_colored.svg";

const Loader = () => {
  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        width: "100%",
        backgroundColor: "#232323",
      }}
    >
      <Logo className="logo" style={{ height: "100px" }} />
      <CircularProgress />
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        Loading console...
      </Typography>
    </Stack>
  );
};

export default Loader;
