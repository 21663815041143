import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { NotificationChanged } from "contexts/Notifications/NotificationChanged";

const Notifier = () => {
  const { notification, setNotification } =
    React.useContext(NotificationChanged);

  const handleExited = () => {
    setNotification(() => ({
      ...notification,
      message: "",
      severity: "error",
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification(() => ({ ...notification, opened: false }));
  };

  return (
    <Snackbar
      open={notification.opened}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.severity}
        sx={{ width: "400px" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Notifier;
