import React from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import {
  Stack,
  Link,
  FormControl,
  InputLabel,
  FilledInput,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SignInFormChanged } from "contexts/Auth/SignInFormChanged";
import { AuthStatusChanged } from "contexts/Auth/AuthStatusChanged";
import { NotificationChanged } from "contexts/Notifications/NotificationChanged";
import { AuthFlowChanged } from "contexts/Auth/AuthFlowChanged";

const initialForm = {
  username: "",
  password: "",
};

const SignIn = () => {
  const [signInForm, setSignInForm] = React.useState(initialForm);

  return (
    <Stack direction="column" spacing={2} sx={{ width: "300px" }}>
      <SignInFormChanged.Provider value={{ signInForm, setSignInForm }}>
        <SignInForm />
        <SignInButton />
      </SignInFormChanged.Provider>
      <LinkToSignUp />
    </Stack>
  );
};

const SignInForm = () => {
  const { signInForm, setSignInForm } = React.useContext(SignInFormChanged);

  const onChange = (event) => {
    setSignInForm(() => ({
      ...signInForm,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <React.Fragment>
      <FormControl focused size="small" sx={{ width: "100%" }} variant="filled">
        <InputLabel>Username</InputLabel>
        <FilledInput name="username" disableUnderline onChange={onChange} />
      </FormControl>
      <FormControl focused size="small" sx={{ width: "100%" }} variant="filled">
        <InputLabel>Password</InputLabel>
        <FilledInput
          type="password"
          name="password"
          disableUnderline
          onChange={onChange}
        />
      </FormControl>
    </React.Fragment>
  );
};

const SignInButton = () => {
  const { signInForm } = React.useContext(SignInFormChanged);
  const { setNotification } = React.useContext(NotificationChanged);
  const { setSignedIn } = React.useContext(AuthStatusChanged);

  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      const { username, password } = signInForm;
      const response = await Auth.signIn(username, password);

      axios.defaults.headers.common["Authorization"] =
        response.signInUserSession.idToken.jwtToken;

      setLoading(false);
      setSignedIn(true);
    } catch (error) {
      const notification = {
        opened: true,
        message: error.message,
        severity: "error",
      };

      setNotification(notification);
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      loading={loading}
      disabled={loading ? true : false}
      variant="contained"
      size="small"
      onClick={handleClick}
    >
      Sign in
    </LoadingButton>
  );
};

const LinkToSignUp = () => {
  const { setAuthFlow } = React.useContext(AuthFlowChanged);

  const handleClick = (event) => {
    setAuthFlow("signUp");
  };

  return (
    <Link component="button" variant="body2" onClick={handleClick}>
      I don't have an account
    </Link>
  );
};

export default SignIn;
