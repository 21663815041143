import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "configs/api.js";
import Auth from "configs/auth.js";
import Theme from "configs/theme.js";
import App from "App";

Amplify.configure(Auth);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);