import { createTheme } from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/idx.css';


const Theme = createTheme(
  {
    
    typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'IDX2',
      'IDX3',
      'IDX4',
      'IDX5',
      'IDX6',
      'IDX7',
      'IDX8',
      'IDX9',
      'sans-serif'
    ].join(','),
  },
    palette: {
      background: {
      default: "#1a1818"
      },
      primary: {
        main: '#309EA6',
        contrastText: '#fff'
      },
      secondary: {
        main: '#136168',
        contrastText: '#fff'
      },
      mode: 'dark'
    },
    components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar()
      },
    },
  },
  }
);

export default Theme;