import React from "react";
import { Stack } from "@mui/material";
import SignIn from "components/Modules/Authenticator/SignIn";
import SignUp from "components/Modules/Authenticator/SignUp";
import ConfirmSignUp from "components/Modules/Authenticator/ConfirmSignUp";
import { UserSignedUp } from "contexts/Auth/UserSignedUp";
import { AuthFlowChanged } from "contexts/Auth/AuthFlowChanged";

const Authenticator = () => {
  const [signedUpUser, setSignedUpUser] = React.useState("");

  const [authFlow, setAuthFlow] = React.useState("signIn");

  return (
    <Stack
      sx={{ height: "100vh", width: "100%" }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <AuthFlowChanged.Provider value={{ authFlow, setAuthFlow }}>
        {authFlow === "signIn" && <SignIn />}
        <UserSignedUp.Provider value={{ signedUpUser, setSignedUpUser }}>
          {authFlow === "signUp" && <SignUp />}
          {authFlow === "confirmSignUp" && <ConfirmSignUp />}
        </UserSignedUp.Provider>
      </AuthFlowChanged.Provider>
    </Stack>
  );
};

export default Authenticator;
