import React from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import Authenticator from "components/Modules/Authenticator/Authenticator";
import Loader from "components/Utilities/Loader/Loader";
import Notifier from "components/Utilities/Notifier/Notifier";
import { AuthStatusChanged } from "contexts/Auth/AuthStatusChanged";
import { NotificationChanged } from "contexts/Notifications/NotificationChanged";

const Console = React.lazy(() => import("components/Modules/Console/Console"));

const defaultNotification = {
  opened: false,
  message: "",
  severity: "error",
};

const App = () => {
  const [signedIn, setSignedIn] = React.useState(false);

  const [notification, setNotification] = React.useState(defaultNotification);

  React.useEffect(() => {
    checkCurrentUser();
  }, []);

  const checkCurrentUser = async () => {
    try {
      const response = await Auth.currentAuthenticatedUser();
      axios.defaults.headers.common["Authorization"] =
        response.signInUserSession.idToken.jwtToken;
      setSignedIn(true);
    } catch (error) {}
  };

  return (
    <AuthStatusChanged.Provider value={{ signedIn, setSignedIn }}>
      <NotificationChanged.Provider value={{ notification, setNotification }}>
        <Notifier />
        {signedIn ? (
          <React.Suspense fallback={<Loader />}>
            <Console />
          </React.Suspense>
        ) : (
          <Authenticator />
        )}
      </NotificationChanged.Provider>
    </AuthStatusChanged.Provider>
  );
};

export default App;