import React from "react";
import { Auth } from "aws-amplify";
import { Stack, FormControl, InputLabel, FilledInput } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { UserSignedUp } from "contexts/Auth/UserSignedUp";
import { ConfirmSignUpFormChanged } from "contexts/Auth/ConfirmSignUpFormChanged";
import { NotificationChanged } from "contexts/Notifications/NotificationChanged";
import { AuthFlowChanged } from "contexts/Auth/AuthFlowChanged";

const initialForm = {
  confirmCode: "",
};

const ConfirmSignUp = () => {
  const [confirmSignUpForm, setConfirmSignUpForm] = React.useState(initialForm);

  return (
    <Stack direction="column" spacing={2} sx={{ width: "300px" }}>
      <ConfirmSignUpFormChanged.Provider
        value={{ confirmSignUpForm, setConfirmSignUpForm }}
      >
        <ConfirmSignUpForm />
        <ConfirmSignUpButton />
      </ConfirmSignUpFormChanged.Provider>
    </Stack>
  );
};

const ConfirmSignUpForm = () => {
  const { confirmSignUpForm, setConfirmSignUpForm } = React.useContext(
    ConfirmSignUpFormChanged
  );

  const onChange = (event) => {
    setConfirmSignUpForm(() => ({
      ...confirmSignUpForm,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <React.Fragment>
      <FormControl focused size="small" sx={{ width: "100%" }} variant="filled">
        <InputLabel>Confirmation code</InputLabel>
        <FilledInput name="confirmCode" disableUnderline onChange={onChange} />
      </FormControl>
    </React.Fragment>
  );
};

const ConfirmSignUpButton = () => {
  const { confirmSignUpForm } = React.useContext(ConfirmSignUpFormChanged);
  const { setNotification } = React.useContext(NotificationChanged);
  const { signedUpUser } = React.useContext(UserSignedUp);
  const { setAuthFlow } = React.useContext(AuthFlowChanged);

  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      const { confirmCode } = confirmSignUpForm;
      await Auth.confirmSignUp(signedUpUser, confirmCode);
      setLoading(false);
      setAuthFlow("signIn");
    } catch (error) {
      const notification = {
        opened: true,
        message: error.message,
        severity: "error",
      };

      setNotification(notification);
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      loading={loading}
      disabled={loading ? true : false}
      variant="contained"
      size="small"
      onClick={handleClick}
    >
      Confirm
    </LoadingButton>
  );
};

export default ConfirmSignUp;
